// solves the wordle shit
import {words} from "./words"

interface SortedWord {
    word : string;
    weight : number;
}

export default class Solver{

    currentWord : string;
    wordsList : SortedWord[];
    lettersMap : Set<string>[]; //which possible letters are available at certain location
    mustHave : Map<string, number>; //if we need certain letters
    defaultList : SortedWord[];
    
    constructor(defaultWord : string){
        this.wordsList = [];
        this.defaultList = [];
        
        for(let word of words){
            if(word.length == 5){
                var tempSet : Set<string> = new Set()
                for(let letter of word){
                    tempSet.add(letter)
                }
                this.wordsList.push({word : word, weight : tempSet.size})
                this.defaultList.push({word : word, weight : tempSet.size})
            } 
        }
        this.wordsList.sort(function(a : SortedWord, b : SortedWord){return b.weight - a.weight})

        this.currentWord = defaultWord;
        this.lettersMap = []
        this.mustHave = new Map()
        const alphabet : string = "abcdefghijklmnopqrstuvwxyz"
        var completeSet : Set<string> = new Set();

        for(var i = 0; i < alphabet.length; i++){
            completeSet.add(alphabet[i])
        }

        for(let i = 0; i < defaultWord.length; i++){
            var clonedSet : Set<string> = new Set(completeSet)
            this.lettersMap.push(clonedSet)
        }

    }

    // sorts word list based on state of current word
    setWordList(state : number[]){
        //console.log(this.lettersMap)
        console.log("SOLVING!", state)
        var neededLetters : Map<string, number> = new Map();
        for(let i = 0; i < state.length; i++){
            var letter : string = this.currentWord[i]
            switch(state[i]){
                case 0:
                    if(neededLetters.has(letter)){
                        this.mustHave.set(letter, neededLetters.get(letter)!)
                    } else{
                        for(var x = 0; x < this.lettersMap.length; x++){
                            this.lettersMap[x].delete(letter)
                        }
                    }
                    break
                case 1:
                    this.lettersMap[i].delete(letter);
                    if(!neededLetters.has(letter)){
                        neededLetters.set(letter, 1)
                    } else{
                        neededLetters.set(letter, neededLetters.get(letter)! + 1)
                    }
                    break
                case 2:
                    if(!neededLetters.has(letter)){
                        neededLetters.set(letter, 1)
                    } else{
                        neededLetters.set(letter, neededLetters.get(letter)! + 1)
                    }
                    var clonedSet : Set<string> = new Set([letter])
                    this.lettersMap[i] = clonedSet
                    break
                default:
                    console.log("How does this even happen?")
            }
        }

        for(const [key, value] of neededLetters.entries()) {
            if(!this.mustHave.has(key)){
                this.mustHave.set(key, -1)
            }
        }

        // also could be more efficient meh
        var newWords : SortedWord[] = []

        for(const {word, weight} of this.wordsList){
            var check : boolean = true;
            
            for(const [letter, value] of this.mustHave.entries()){
                var cnt : number = this.countLetters(word, letter);
                if(value == -1 && !cnt || (value != -1 && cnt != value)){ 
                    check = false;
                    break;
                }
            }

            if(check){
                for(let i = 0; i < word.length; i++){
                    if(!this.lettersMap[i].has(word[i])) {
                        check = false;
                        break;
                    }
                }
                if(check) newWords.push({word : word, weight : weight})
            }
        }
        this.wordsList = newWords.slice()
        console.log(this.wordsList)
    }

    countLetters(word : string, letter : string) : number {
        var cnt : number = 0;
        for(let c of word){
            if(c == letter) cnt += 1;
        }
        return cnt;
    }

    // do we really need this function? maybe not
    removeWord(word : string) : void {

    }

    newWord() : string {
        if(!this.wordsList.length){
            return '?????'
        }
        var topWeight : number = this.wordsList[0].weight
        var upperBound = 1;
        while(upperBound < this.wordsList.length && this.wordsList[upperBound].weight == topWeight) upperBound += 1;    
        upperBound -= 1
        this.currentWord = this.wordsList[Math.floor(Math.random() * (upperBound))].word
        return this.currentWord
    }

}