import React, {useEffect, useState, useRef} from 'react';
import './css/daniel.css';
import Rank from './components/Rank'
import Solver from './solver'

const defaultWord : string = "adieu";

interface rankInfo {
  word : string;
  defaultState : number[];
  active : boolean;
}

var solver : Solver = new Solver(defaultWord)

function Knight(){
  return (
    <button><img src="./scratch_Cat.png" alt="my image" /></button>
  )
}

function Daniel() {

  const firstRender = useRef(true);
  const [ranks, setRanks] = useState<rankInfo[]>([])
  const [newRank, setNewRank] = useState<rankInfo>()

  //initialize component
  useEffect(() => {
    ranks.push({word : defaultWord, defaultState : [0,0,0,0,0], active : true})
    setRanks([...ranks])
  }, []);

  useEffect(() => {

    // next rank
    if(!firstRender.current){
      solver.setWordList(newRank!.defaultState);
      var newWord : string = solver.newWord()
      ranks[ranks.length - 1].active = false
      var newState : number[] = newRank!.defaultState.slice()
      for(let i = 0; i < newState.length; i++) newState[i] = newState[i] == 2 ? 2 : 0
      ranks.push({word : newWord, defaultState : newState, active : true})
      setRanks([...ranks])
    } else{
      firstRender.current = false;
    }
  }, [newRank])
  

  return (
    <div className="App">
      <header className="App-header">
        <div className = "title-layout">
          <div className = "knight">
            {/* {Knight()} */}
          </div>
          <h1 className = "main-title"> DrunkWordle </h1>
        </div>
          {ranks!.map((rank : rankInfo, idx : number) => (
            <Rank key = {idx} num = {idx} setNewRank = {setNewRank} word = {rank.word} active = {rank.active} defaultState = {rank.defaultState}/>
          ))}
        <p className = "credits-1">
          Developed by Derek Ma
        </p>
        <p className = "credits">
          Further credits to Daniel, Eri, and the ECE gang
        </p>
      </header>
    </div>
  );
}

export default Daniel;
