import React, {useState, useEffect, useRef} from 'react'

import "./../css/square.css"

/*
Colors are dependent on state:
0 --> grey
1 --> yellow
2 --> green
*/

interface squareProps{  
    letter : string,
    index : number,
    state : number, 
    selected : boolean,
    active : boolean,
    addSquare? : any,
}

const squareBorderWidth : number = 1.5;
const squareColors : string[] = ["#3A3A3C", "#B59F3B", "#538D4E"] // --> grey, yellow, green
const gradientColors : string[] = ["#6d6d73", "#dbc048", "#6dba66"]

// func returns canvas location to rank
export default function Square({active, selected, letter, index, state, addSquare} : squareProps){

    const canvas = useRef<HTMLCanvasElement>(null);
    var squareSize : number = 57;

    // ** def can make more efficient **
    const [windowSize, setWindowSize] = useState({ //disgustingly slow but best I can do for now
        width : window.innerWidth,
        height : window.innerHeight
    })

    useEffect(() => {
        if(canvas.current){
            //console.log("Trying to add square!")
            addSquare(index, canvas.current.getBoundingClientRect());
        }

        function handleResize(){
            //console.log("RESIZING!")
            setWindowSize({
                width : window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener("resize", handleResize);
        handleResize()

    }, [])

    useEffect(() => {

        //console.log(letter, index, state, func)
        if(canvas.current){

            addSquare(index, canvas.current.getBoundingClientRect(), true); //constantly update location

            //hidpi magic
            const ratio = window.devicePixelRatio;
            canvas.current.width = squareSize * ratio;
            canvas.current.height = squareSize * ratio;
            canvas.current.style.width = squareSize + "px";
            canvas.current.style.height = squareSize + "px";

            const ctx = canvas.current.getContext("2d")!;

            ctx.scale(ratio, ratio);

            //define styling
            //console.log(state)
            // console.log(squareColors[state])
            if(!selected){
                ctx.fillStyle = squareColors[state];
            } else{
                console.log("YES!")
                //var gradient = ctx.createRadialGradient(50, 50, 10, 50, 50, 10)
                // gradient.addColorStop(0, "white");
                // gradient.addColorStop(1, squareColors[state])
                var gradient = ctx.createRadialGradient(squareSize / 2,squareSize / 2, squareSize, squareSize / 2, squareSize / 2, 35);

                // Add three color stops
                gradient.addColorStop(0, squareColors[state]);
                gradient.addColorStop(1, gradientColors[state]);
                ctx.fillStyle = gradient
            }
            ctx.fillRect(0,0,squareSize,squareSize);
            // ctx.fillStyle = "#121213"
            // ctx.fillRect(squareBorderWidth, squareBorderWidth, squareSize - squareBorderWidth * 2, squareSize - squareBorderWidth * 2);
            
            if(!selected){
                ctx.font = "bold 34px Segoe UI"
            } else{
                ctx.font = "bold 40px Segoe UI"
            }
            ctx.fillStyle = "white"
            ctx.textAlign = "center"
            ctx.textBaseline = "middle"

            if(letter){
                ctx.fillText(letter.toUpperCase(), squareSize / 2, 31);
            }
            
        }

    }, [state, canvas, windowSize, selected])
    
    return (
        <div style = {{ cursor : active ? "pointer" : "default"}}>
            <canvas ref = {canvas} width = {squareSize.toString()} height = {squareSize.toString()}/>
        </div>
    )
}