import React, {useState, useEffect, useRef} from 'react'

import Square from './Square'
import './../css/rank.css'

interface rankProps {
    word : string;
    active : boolean;
    defaultState : number[];
    setNewRank : any;
    num : number;
}

interface squareLoc {
    idx : number;
    top: number;
    bottom: number;
    right: number;
    left: number;
}

var currentRows : number = 1;
var squareLocations : squareLoc[] = [];
var prevSelectedSquare : number = -1;

// pointer refers to the mouse pointer
// callback from square
function addSquareLocation(idx : number, pointer : any, update : boolean = false) : void {
    if(!update){
        //console.log("Adding square", idx, "!")
        //console.log(pointer)
        // console.log(numberOfSquares)
        if(squareLocations.length < currentRows * 5){
            //console.log("Adding square", idx, "!")
            squareLocations.push({idx : idx, top : pointer.top, bottom : pointer.bottom, right : pointer.right, left : pointer.left})
        }
    } else {
        //console.log("Updating!")
        squareLocations[idx + (currentRows - 1) * 5] = {idx : idx, top : pointer.top, bottom : pointer.bottom, right : pointer.right, left : pointer.left};
    }
    //console.log(squareLocations)
}

// should be able to pass in greens from last rank
// ** need to add **
var lastClick : Date = new Date();

// when button is clicked
function done(word : string, state : number[], setNewRank : any){
    setNewRank({word : word, defaultState : state})
}

export default function Rank({word, active, defaultState, setNewRank, num} : rankProps){
    const [pointer, setPointer] = useState<any>();
    const [ogState, setOgState] = useState<number[]> (defaultState)
    const [mouseClick, setClick] = useState<any>();
    const [currentKey, setCurrentKey] = useState<any>();
    const [squaresState, setSquaresState] = useState<number[]>(defaultState);
    const [squareSelected, setSquareSelected] = useState<number>(-1);


    useEffect(() => {
        // ensures we don't have a shit ton of overlapping listeners
        // cleaner way would be to initialize one listener in Daniel.tsx but meh
        console.log("Making New Rank!")
        if(active){
            // mouse movemenet
            window.addEventListener('mousemove', e => setPointer(e))
            // mouse click
            window.addEventListener('mousedown', e => setClick(e))
            window.addEventListener('keydown', e => setCurrentKey(e))
            window.addEventListener('keyup', e => setCurrentKey(null))         
        }   

    }, [])

    // when mouse is clicked
    useEffect(() => {

        if(active){
            // revert based on double click? logic would have to be complicated
            var currentTime : Date = new Date()
            var timeDif : number = currentTime.getTime() - lastClick.getTime();
            if(mouseClick && squareSelected != -1){
                ogState[squareSelected] = (ogState[squareSelected] + 1) % 3
                setSquaresState([...ogState])
            }
            lastClick = currentTime
        }
    }, [mouseClick])

    // when mouse moves
    // detects if a square is selected
    // definitely could be much more efficient but let's not worry about it for now...
    // we're rendering the state everytime we move the mouse rip
    useEffect(() => {
        //console.log(squaresState)
        if(pointer && active){
            // loop through locations of squares
            const cursorX : number = pointer.clientX;
            const cursorY : number = pointer.clientY;
            //console.log(state)
            for(var i = (currentRows - 1) * 5; i < currentRows * 5; i++){
                var sLoc : any = squareLocations[i]
                // within square
                if(cursorY > sLoc.top && cursorY < sLoc.bottom && cursorX > sLoc.left && cursorX < sLoc.right){
                    setSquareSelected(squareSelected => sLoc.idx)
                    var tempState : any[] = ogState.slice()
                    //tempState[sLoc.idx] = (ogState[sLoc.idx] + 1) % 3;
                    setSquaresState([...tempState])       
                    //console.log(tempState)

                    return
                } 
            }
            setSquareSelected(-1)
            setSquaresState([...ogState])
            prevSelectedSquare = -1
        }
    }, [pointer, currentKey, mouseClick])
   
    return (
        <div>
            <div className = "rank">
                {squaresState.map((state : number, idx : number) => (
                    <Square active = {active} selected = {squareSelected == idx} addSquare = {addSquareLocation} key = {idx} letter = {word[idx]} index = {idx} state = {state}/>
                ))}
            </div>
            {active ? <button onClick = {() => {done(word, squaresState, setNewRank); currentRows += 1}} className = "btn"> Next Word Please! </button> : null}
        </div>
    )

}